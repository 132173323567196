(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({"/root/systemanalysebogen/client/src/js/app.js":[function(require,module,exports){
'use strict';

var app = angular.module('Systemanalysebogen', ['ngRoute', 'ngCookies', 'angularMoment', 'ngSanitize']);

app.config(['$routeProvider', '$locationProvider', require('./routes.js')]);

app.run(function (amMoment) {
    amMoment.changeLocale('de');
});

require('./controller/index');
require('./service/index');
},{"./controller/index":"/root/systemanalysebogen/client/src/js/controller/index.js","./routes.js":"/root/systemanalysebogen/client/src/js/routes.js","./service/index":"/root/systemanalysebogen/client/src/js/service/index.js"}],"/root/systemanalysebogen/client/src/js/controller/habelCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans
 */
module.exports = function($scope, $filter, $location, $http, pageService) {

    var that = this;
    this.bearbeiter = '';
    this.schnittstellen = 0;

    this.sys = {
        kontakt:          {
            kunde: ''
        },
        dmsKennzahlen:    {
            workflow: 'nein'
        },
        aktuellesDMS:     {
            vorhanden: 'nein'
        },
        mailArchivierung: {
            archivierung: 'nein',
            type:         'Journal'
        },
        zusatzfunktionen: {
            personalakte:       'nein',
            vertragsmanagement: 'nein',
            gfarchiv:           'nein',
            versch:             'nein',
            app:                'nein'
        },
        sprachen:         {
            weitere:        'nein',
            Englisch:       'nein',
            Französisch:    'nein',
            Ungarisch:      'nein',
            Italienisch:    'nein',
            Chinesisch:     'nein',
            Portugiesisch:  'nein',
            Spanisch:       'nein',
            Tschechisch:    'nein',
            Russisch:       'nein',
            Niederländisch: 'nein',
            Japanisch:      'nein',
            Polnisch:       'nein',
            Dänisch:        'nein',
            Finnisch:       'nein',
            Flämisch:       'nein',
            Schwedisch:     'nein'
        },
        belegleser:       {
            belegleser:      'nein',
            datentyp:        'kopfdaten',
            dokumentenarten: 'eingsrechnungen'
        },
        volltext:         {
            volltext:  'nein',
            dokumente: 'alle'
        },
        schnittstellen:   {
            schnittstellen: []
        },
        revision:         0,
        bearbeiter:       ''
    };

    this.sprachen = [
        'Englisch',
        'Französisch',
        'Ungarisch',
        'Italienisch',
        'Chinesisch',
        'Portugiesisch',
        'Spanisch',
        'Tschechisch',
        'Russisch',
        'Niederländisch',
        'Japanisch',
        'Polnisch',
        'Dänisch',
        'Finnisch',
        'Flämisch',
        'Schwedisch'
    ];

    function init() {
        var searchObject;
        searchObject = $location.search();
        if(searchObject.hasOwnProperty('source')) {
            $http.get('/api/v1/habel/persist/' + searchObject.source)
                .then(function(res) {
                    that.sys = res.data;
                    that.loaded = true;
                    that.bearbeiter = that.sys.bearbeiter;
                    that.sys.bearbeiter = '';
                    that.schnittstellen = that.sys.schnittstellen.schnittstellen.length;
                }, function(err) {
                    console.log(err);
                    $location.url($location.path());
                });
        }
    }

    this.addSchnittstelle = function(system) {
        that.schnittstellen++;
        this.inserted = {
            id:           that.schnittstellen,
            system:       system,
            beschreibung: ''
        };
        this.sys.schnittstellen.schnittstellen.push(this.inserted);
    };

    this.removeSchnittstelle = function(index) {
        this.sys.schnittstellen.schnittstellen.splice(this.sys.schnittstellen.schnittstellen.indexOf(index), 1);
    };

    this.getWorkflowButtonState = function(state) {
        return this.sys.dmsKennzahlen.workflow === state ? 'active' : '';
    };

    this.setWorkflow = function(state) {
        this.sys.dmsKennzahlen.workflow = state;
    };

    this.getCurrentDMSButtonState = function(state) {
        return this.sys.aktuellesDMS.vorhanden === state ? 'active' : '';
    };

    this.setCurrentDMS = function(state) {
        this.sys.aktuellesDMS.vorhanden = state;
    };

    this.getMailButtonState = function(state) {
        return this.sys.mailArchivierung.archivierung === state ? 'active' : '';
    };

    this.setMail = function(state) {
        this.sys.mailArchivierung.archivierung = state;
    };

    this.getMailTypeButtonState = function(state) {
        return this.sys.mailArchivierung.type === state ? 'active' : '';
    };

    this.setMailType = function(state) {
        this.sys.mailArchivierung.type = state;
    };

    this.getPersonalakteState = function(state) {
        return this.sys.zusatzfunktionen.personalakte === state ? 'active' : '';
    };

    this.setPersonalakte = function(state) {
        this.sys.zusatzfunktionen.personalakte = state;
    };

    this.getVertragsmanagementState = function(state) {
        return this.sys.zusatzfunktionen.vertragsmanagement === state ? 'active' : '';
    };

    this.setVertragsmanagement = function(state) {
        this.sys.zusatzfunktionen.vertragsmanagement = state;
    };

    this.getGFArchivState = function(state) {
        return this.sys.zusatzfunktionen.gfarchiv === state ? 'active' : '';
    };

    this.setGFArchiv = function(state) {
        this.sys.zusatzfunktionen.gfarchiv = state;
    };

    this.getVerschArchivState = function(state) {
        return this.sys.zusatzfunktionen.versch === state ? 'active' : '';
    };

    this.setVerschArchiv = function(state) {
        this.sys.zusatzfunktionen.versch = state;
    };

    this.getAppState = function(state) {
        return this.sys.zusatzfunktionen.app === state ? 'active' : '';
    };

    this.setApp = function(state) {
        this.sys.zusatzfunktionen.app = state;
    };

    this.getLanguageButtonState = function(state) {
        return this.sys.sprachen.weitere === state ? 'active' : '';
    };

    this.setLanguage = function(state) {
        this.sys.sprachen.weitere = state;
    };

    this.getBelegleserButtonState = function(state) {
        return this.sys.belegleser.belegleser === state ? 'active' : '';
    };

    this.setBelegleser = function(state) {
        this.sys.belegleser.belegleser = state;
    };

    this.getVolltextButtonState = function(state) {
        return this.sys.volltext.volltext === state ? 'active' : '';
    };

    this.setVolltext = function(state) {
        this.sys.volltext.volltext = state;
    };

    this.getVolltextDokumenteButtonState = function(state) {
        return this.sys.volltext.dokumente === state ? 'active' : '';
    };

    this.setVolltextDokumente = function(state) {
        this.sys.volltext.dokumente = state;
    };

    this.getBelegleserDatentypButtonState = function(state) {
        return this.sys.belegleser.datentyp === state ? 'active' : '';
    };

    this.setBelegleserDatentyp = function(state) {
        this.sys.belegleser.datentyp = state;
    };

    this.getBelegleserDokumentenartenButtonState = function(state) {
        return this.sys.belegleser.dokumentenarten === state ? 'active' : '';
    };

    this.setBelegleserDokumentenarten = function(state) {
        this.sys.belegleser.dokumentenarten = state;
    };

    this.getSpracheButtonState = function(sprache, state) {
        return this.sys.sprachen[sprache] === state ? 'active' : '';
    };

    this.setSprache = function(sprache, state) {
        this.sys.sprachen[sprache] = state;
    };

    this.export = function() {
        console.log(that.sys);
        $('#sendModal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();

        $http.post('/api/v1/habel/persist', {
            name:     that.sys.kontakt.kunde,
            value:    JSON.stringify(that.sys),
            revision: that.sys.revision
        })
            .then(function(res) {
                pageService.go('/habel/send/' + res.data);
            }, function(err) {
                console.log(err);
            });
    };

    init();
};
},{}],"/root/systemanalysebogen/client/src/js/controller/index.js":[function(require,module,exports){
'use strict';

var app = angular.module('Systemanalysebogen');

app.controller('mainCtrl', [require('./mainCtrl')]);
app.controller('navCtrl', ['pageService', require('./navCtrl')]);

app.controller('mfpCtrl', ['$scope', '$filter', '$location', '$http', 'pageService', require('./mfpCtrl')]);
app.controller('sendMfpCtrl', ['$routeParams', '$location', require('./sendMfpCtrl')]);

app.controller('starfaceCtrl', ['$scope', '$filter', '$location', '$http', 'pageService', require('./starfaceCtrl')]);
app.controller('sendStarfaceCtrl', ['$routeParams', '$location', require('./sendStartfaceCtrl')]);

app.controller('nsiCtrl', ['$scope', '$filter', '$location', '$http', 'pageService', require('./nsiCtrl')]);
app.controller('sendNsiCtrl', ['$routeParams', '$location', require('./sendNsiCtrl')]);

app.controller('habelCtrl', ['$scope', '$filter', '$location', '$http', 'pageService', require('./habelCtrl')]);
app.controller('sendHabelCtrl', ['$routeParams', '$location', require('./sendHabelCtrl')]);
},{"./habelCtrl":"/root/systemanalysebogen/client/src/js/controller/habelCtrl.js","./mainCtrl":"/root/systemanalysebogen/client/src/js/controller/mainCtrl.js","./mfpCtrl":"/root/systemanalysebogen/client/src/js/controller/mfpCtrl.js","./navCtrl":"/root/systemanalysebogen/client/src/js/controller/navCtrl.js","./nsiCtrl":"/root/systemanalysebogen/client/src/js/controller/nsiCtrl.js","./sendHabelCtrl":"/root/systemanalysebogen/client/src/js/controller/sendHabelCtrl.js","./sendMfpCtrl":"/root/systemanalysebogen/client/src/js/controller/sendMfpCtrl.js","./sendNsiCtrl":"/root/systemanalysebogen/client/src/js/controller/sendNsiCtrl.js","./sendStartfaceCtrl":"/root/systemanalysebogen/client/src/js/controller/sendStartfaceCtrl.js","./starfaceCtrl":"/root/systemanalysebogen/client/src/js/controller/starfaceCtrl.js"}],"/root/systemanalysebogen/client/src/js/controller/mainCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans
 */
module.exports = function () {
    var that = this;
};
},{}],"/root/systemanalysebogen/client/src/js/controller/mfpCtrl.js":[function(require,module,exports){
'use strict';

module.exports = function($scope, $filter, $location, $http, pageService) {
    var that = this;

    this.bearbeiter = '';
    this.geraete = 0;

    this.sys = {
        kontakt:        {
            kunde: ''
        },
        geraete:        [],
        druckertreiber: {
            installation: 'kunde'
        },
        scan:           {
            installation: 'kunde',
            scan2mail:    false,
            scan2file:    false
        },
        fax:            {
            installation:  'kunde',
            konfiguration: []
        },
        fleetmanager:   {
            installation: 'kunde'
        },
        revision:       0,
        bearbeiter:     ''
    };

    this.setClientOS = function(option) {
        if(!option.isChecked) {
            option.sys32 = false;
            option.sys64 = false;
        }
        that.sys.druckertreiber.clientOS = that.operationSystem;
    };

    function init() {
        var searchObject;
        searchObject = $location.search();
        if(searchObject.hasOwnProperty('source')) {
            $http.get('/api/v1/mfp/persist/' + searchObject.source)
                .then(function(res) {
                    that.sys = res.data;
                    that.loaded = true;
                    that.bearbeiter = that.sys.bearbeiter;
                    that.sys.bearbeiter = '';
                    if(that.sys.druckertreiber.clientOS) {
                        that.operationSystem = that.sys.druckertreiber.clientOS;
                    }
                    that.geraete = that.sys.geraete.length;
                }, function(err) {
                    console.log(err);
                    $location.url($location.path());
                });
        }
    }

    this.connectionTypes = [
        {value: 1, text: 'USB'},
        {value: 2, text: 'Ethernet'},
        {value: 3, text: 'WiFi'}
    ];

    this.architecture = [
        {value: 1, text: 'Client / Server'},
        {value: 2, text: 'Peer-to-Peer'}
    ];

    this.operationSystem = [
        {value: 1, text: 'Windows 11', isChecked: false, sys32: false, sys64: false},
        {value: 2, text: 'Windows 10', isChecked: false, sys32: false, sys64: false},
        {value: 3, text: 'Windows  8', isChecked: false, sys32: false, sys64: false},
        {value: 4, text: 'Windows  7', isChecked: false, sys32: false, sys64: false},
        {value: 5, text: 'Windows  Vista', isChecked: false, sys32: false, sys64: false},
        {value: 6, text: 'GNU/Linux', isChecked: false, sys32: false, sys64: false},
        {value: 7, text: 'Mac OS', isChecked: false, sys32: false, sys64: false}
    ];

    this.serverOperationSystem = [
        {value: 1, text: 'Windows Server 2022'},
        {value: 2, text: 'Windows Server 2019'},
        {value: 3, text: 'Windows Server 2016'},
        {value: 4, text: 'Windows Server 2012 R2'},
        {value: 5, text: 'Windows Server 2012'},
        {value: 6, text: 'Windows Server 2008 R2'},
        {value: 7, text: 'Windows Server 2008'}
    ];

    // Ebenerdig
    this.setEbenerdig = function(device, state) {
        this.sys.geraete[this.sys.geraete.indexOf(device)].ebenerdig = state;
    };
    this.getEbenerdig = function(device) {
        return this.sys.geraete[this.sys.geraete.indexOf(device)].ebenerdig;
    };
    this.getEbenerdigButtonState = function(device, state) {
        return this.sys.geraete[this.sys.geraete.indexOf(device)].ebenerdig == state ? 'active' : '';
    };

    // Fleetmanager
    this.setFleet = function(state) {
        this.sys.kontakt.fleet = state;
    };
    this.getFleet = function() {
        return this.sys.kontakt.fleet;
    };
    this.getFleetButtonState = function(state) {
        return this.sys.kontakt.fleet == state ? 'active' : '';
    };

    // IpMode
    this.setIpMode = function(device, ipMode) {
        this.sys.geraete[this.sys.geraete.indexOf(device)].ipMode = ipMode;
    };
    this.getIpMode = function(device) {
        return this.sys.geraete[this.sys.geraete.indexOf(device)].ipMode;
    };
    this.getIpModeButtonState = function(device, ipMode) {
        return this.sys.geraete[this.sys.geraete.indexOf(device)].ipMode == ipMode ? 'active' : '';
    };

    // Installation Treiber
    this.setInstallDriver = function(state) {
        this.sys.druckertreiber.installation = state;
    };
    this.getInstallDriver = function() {
        return this.sys.druckertreiber.installation;
    };
    this.getInstallDriverButtonState = function(state) {
        return this.sys.druckertreiber.installation == state ? 'active' : '';
    };

    // Installation Scan
    this.setInstallScan = function(state) {
        this.sys.scan.installation = state;
    };
    this.getInstallScan = function() {
        return this.sys.scan.installation;
    };
    this.getInstallScanButtonState = function(state) {
        return this.sys.scan.installation == state ? 'active' : '';
    };

    // Installation Fax
    this.setInstallFax = function(state) {
        this.sys.fax.installation = state;
    };
    this.getInstallFax = function() {
        return this.sys.fax.installation;
    };
    this.getInstallFaxButtonState = function(state) {
        return this.sys.fax.installation == state ? 'active' : '';
    };

    // Installation Fleetmanager
    this.setInstallFleet = function(state) {
        this.sys.fleetmanager.installation = state;
    };
    this.getInstallFleet = function() {
        return this.sys.fleetmanager.installation;
    };
    this.getInstallFleetButtonState = function(state) {
        return this.sys.fleetmanager.installation == state ? 'active' : '';
    };

    // Server OS Bit
    this.setServerOSBit = function(bit) {
        this.sys.druckertreiber.serverOSBit = bit;
    };
    this.getServerOSBitButtonState = function(bit) {
        return this.sys.druckertreiber.serverOSBit == bit ? 'active' : '';
    };

    // Scan2Mail
    this.setInstallScan2Mail = function(state) {
        this.sys.scan.scan2mail = state;
    };
    this.getInstallScan2Mail = function() {
        return this.sys.scan.scan2mail;
    };
    this.getInstallScan2MailButtonState = function(state) {
        return this.sys.scan.scan2mail == state ? 'active' : '';
    };

    // Scan2File
    this.setInstallScan2File = function(state) {
        this.sys.scan.scan2file = state;
    };
    this.getInstallScan2File = function() {
        return this.sys.scan.scan2file;
    };
    this.getInstallScan2FileButtonState = function(state) {
        return this.sys.scan.scan2file == state ? 'active' : '';
    };

    this.showConnectionType = function(connectionType) {
        var selected = [];
        if(connectionType) {
            selected = $filter('filter')(this.connectionTypes, {value: connectionType});
        }
        return selected.length ? selected[0].text : 'Nicht ausgew�hlt';
    };

    this.removeDevice = function(index) {
        this.sys.geraete.splice(this.sys.geraete.indexOf(index), 1);
    };

    this.getOnlyUSBDevice = function() {
        for(var i = 0; i < that.sys.geraete.length; i++) {
            if(that.sys.geraete[i].verbindung != 1) {
                return false;
            }
        }
        that.sys.druckertreiber.architektur = '';
        return true;
    };

    this.addDevice = function(name) {
        that.geraete++;
        this.inserted = {
            id:         that.geraete,
            name:       name,
            ort:        '',
            ebenerdig:  true,
            verbindung: '',
            ipMode:     'static'
        };
        if(this.sys.geraete.length > 0) {
            this.inserted.subnet = this.sys.geraete[this.sys.geraete.length - 1].subnet;
            this.inserted.gateway = this.sys.geraete[this.sys.geraete.length - 1].gateway;
        }
        this.sys.geraete.push(this.inserted);
    };

    this.addFaxConfiguration = function() {
        that.geraete++;
        this.inserted = {
            id:         that.geraete,
            nr:         '',
            kennung:    '',
            stelle:     '',
            voip:       '',
            adressbuch: ''
        };
        if(this.sys.fax.konfiguration === undefined) {
            this.sys.fax.konfiguration = [];
        }
        this.sys.fax.konfiguration.push(this.inserted);
    };

    this.export = function() {
        $('#sendModal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();

        $http.post('/api/v1/mfp/persist', {
            name:     that.sys.kontakt.kunde,
            value:    JSON.stringify(that.sys),
            revision: that.sys.revision
        })
            .then(function(res) {
                pageService.go('/mfp/send/' + res.data);
            }, function(err) {
                console.log(err);
            });
    };

    this.print = function() {
        var data = $.extend(true, {}, that.sys);
        for(var entry in data.geraete) {
            if(data.geraete[entry].verbindung > 0) {
                data.geraete[entry].verbindung = that.connectionTypes[data.geraete[entry].verbindung - 1].text;
            }
        }
        if(data.fax.installation === 'itz' && data.geraete.length > 1) {
            for(var entry in data.geraete) {
                if(data.fax.device == data.geraete[entry].id) {
                    data.geraete[entry].fax = data.fax;
                }
            }
            if(data.fax.konfiguration && data.fax.konfiguration.length > 0) {
                for(var fax in data.fax.konfiguration) {
                    for(var entry in data.geraete) {
                        if(data.fax.konfiguration[fax].device == data.geraete[entry].id) {
                            data.geraete[entry].fax = data.fax.konfiguration[fax];
                        }
                    }
                }
            }
        }
        if(data.druckertreiber.architektur > 0) {
            data.druckertreiber.architektur = that.architecture[data.druckertreiber.architektur - 1].text;
        }
        if(data.druckertreiber.serverOS > 0) {
            data.druckertreiber.serverOS = that.serverOperationSystem[data.druckertreiber.serverOS - 1].text;
        }
        if(data.druckertreiber.p2pOS > 0) {
            data.druckertreiber.p2pOS = that.operationSystem[data.druckertreiber.p2pOS - 1].text;
        }
        pageService.print('../pdfTemplateMFP.html', data);
    };

    init();
};
},{}],"/root/systemanalysebogen/client/src/js/controller/navCtrl.js":[function(require,module,exports){
'use strict';

module.exports = function (pageService) {


    this.go = function (path) {
        pageService.go(path);
    };
};
},{}],"/root/systemanalysebogen/client/src/js/controller/nsiCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans
 */
module.exports = function ($scope, $filter, $location, $http, pageService) {

    var that = this;

    this.bearbeiter = "";
    this.clients = 0;
    this.workflow = 0;
    this.metadaten = 0;

    this.sys = {
        kontakt: {
            kunde: ""
        },
        clients: {
            geraete: []
        },
        workflows: [],
        revision: 0,
        bearbeiter: ""
    };

    function init() {
        var searchObject;
        searchObject = $location.search();
        if (searchObject.hasOwnProperty('source')) {
            $http.get('/api/v1/nsi/persist/' + searchObject.source)
                .then(function (res) {
                    that.sys = res.data;
                    that.loaded = true;
                    that.bearbeiter = that.sys.bearbeiter;
                    that.sys.bearbeiter = "";
                    that.clients = that.sys.clients.geraete.length;
                    that.workflow = that.sys.workflows.length;
                }, function (err) {
                    console.log(err);
                    $location.url($location.path());
                })
        } else {
            that.addClient();
        }
    }

    this.herstellerClient = [
        {value: 1, text: 'Ricoh MFP'},
        {value: 2, text: 'Kyocera MFP'},
        {value: 3, text: 'Client PC'}
    ];

    this.resolutionTypes = [
        {value: 1, text: '100 DPI'},
        {value: 2, text: '200 DPI'},
        {value: 3, text: '300 DPI'},
        {value: 4, text: '400 DPI'},
        {value: 5, text: '500 DPI'},
        {value: 6, text: '600 DPI'}
    ];

    this.ocrOption = [
        {value: 1, text: 'TXT'},
        {value: 2, text: 'CSV'},
        {value: 3, text: 'HTML'},
        {value: 4, text: 'PDF'},
        {value: 5, text: 'PDF/A'},
        {value: 6, text: 'RTF'},
        {value: 7, text: 'DOCX'},
        {value: 8, text: 'XLS'},
        {value: 9, text: 'XLSX'},
        {value: 10, text: 'PPTX'}
    ];

    this.removeClient = function (client) {
        this.sys.clients.geraete.splice(this.sys.clients.geraete.indexOf(client), 1);
    };

    this.addClient = function () {
        this.clients++;
        this.inserted = {
            id: this.clients,
            ip: '',
            hersteller: ''
        };
        this.sys.clients.geraete.push(this.inserted);
    };

    this.clientsContainPc = function () {
        for (var index in that.sys.clients.geraete) {
            if (that.sys.clients.geraete[index].hersteller === 'Client PC')
                return true;
        }
        return false;
    };

    this.addWorkflow = function () {
        this.workflow++;
        this.inserted = {
            id: this.workflow,
            metadatenstatus: 'nein',
            seiten: 'nein',
            stempel: 'nein',
            ocr: 'nein',
            trennblatt: 'nein',
            barcode: 'nein',
            verzeichnis: 'nein',
            mail: 'nein',
            metadaten: [],
            fieldCount: 0
        };
        this.sys.workflows.push(this.inserted);
    };

    this.getMetadatenButtonState = function (workflow, state) {
        return this.sys.workflows[this.sys.workflows.indexOf(workflow)].metadatenstatus == state ? 'active' : '';
    };

    this.setMetadaten = function (workflow, state) {
        var workflow = this.sys.workflows[this.sys.workflows.indexOf(workflow)];
        if (state === 'ja' && workflow.metadaten.length < 1) {
            this.addWorkflowField(workflow);
        } else {
            workflow.metadaten = [];
        }
        workflow.metadatenstatus = state;
    };

    this.removeWorkflow = function (workflow) {
        this.sys.workflows.splice(this.sys.workflows.indexOf(workflow), 1);
    };

    this.addWorkflowField = function (workflow) {
        var workflow = this.sys.workflows[this.sys.workflows.indexOf(workflow)];
        workflow.fieldCount++;
        this.inserted = {
            id: workflow.fieldCount,
            name: ''
        };
        workflow.metadaten.push(this.inserted);
    };

    this.removeWorkflowField = function (workflow, field) {
        var workflow_metadaten = this.sys.workflows[this.sys.workflows.indexOf(workflow)].metadaten;
        workflow_metadaten.splice(workflow_metadaten.indexOf(field), 1);
    };

    this.getBarcodeButtonState = function (workflow, state) {
        return this.sys.workflows[this.sys.workflows.indexOf(workflow)].barcode == state ? 'active' : '';
    };

    this.setBarcode = function (workflow, state) {
        var workflow = this.sys.workflows[this.sys.workflows.indexOf(workflow)];
        workflow.barcode = state;
    };

    this.getTrennblattButtonState = function (workflow, state) {
        return this.sys.workflows[this.sys.workflows.indexOf(workflow)].trennblatt == state ? 'active' : '';
    };

    this.setTrennblatt = function (workflow, state) {
        var workflow = this.sys.workflows[this.sys.workflows.indexOf(workflow)];
        workflow.trennblatt = state;
    };

    this.getOCRButtonState = function (workflow, state) {
        return this.sys.workflows[this.sys.workflows.indexOf(workflow)].ocr == state ? 'active' : '';
    };

    this.setOCR = function (workflow, state) {
        var workflow = this.sys.workflows[this.sys.workflows.indexOf(workflow)];
        workflow.ocr = state;
    };

    this.getSeitenButtonState = function (workflow, state) {
        return this.sys.workflows[this.sys.workflows.indexOf(workflow)].seiten == state ? 'active' : '';
    };

    this.setSeiten = function (workflow, state) {
        var workflow = this.sys.workflows[this.sys.workflows.indexOf(workflow)];
        workflow.seiten = state;
    };

    this.getStempelButtonState = function (workflow, state) {
        return this.sys.workflows[this.sys.workflows.indexOf(workflow)].stempel == state ? 'active' : '';
    };

    this.setStempel = function (workflow, state) {
        var workflow = this.sys.workflows[this.sys.workflows.indexOf(workflow)];
        workflow.stempel = state;
    };

    this.getVerzeichnisButtonState = function (workflow, state) {
        return this.sys.workflows[this.sys.workflows.indexOf(workflow)].verzeichnis == state ? 'active' : '';
    };

    this.setVerzeichnis = function (workflow, state) {
        var workflow = this.sys.workflows[this.sys.workflows.indexOf(workflow)];
        workflow.verzeichnis = state;
    };

    this.getMailButtonState = function (workflow, state) {
        return this.sys.workflows[this.sys.workflows.indexOf(workflow)].mail == state ? 'active' : '';
    };

    this.setMail = function (workflow, state) {
        var workflow = this.sys.workflows[this.sys.workflows.indexOf(workflow)];
        workflow.mail = state;
    };

    this.export = function () {
        console.log(that.sys);
        $('#sendModal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();

        $http.post('/api/v1/nsi/persist', {
            name: that.sys.kontakt.kunde,
            value: JSON.stringify(that.sys),
            revision: that.sys.revision
        })
            .then(function (res) {
                pageService.go('/nsi/send/' + res.data);
            }, function (err) {
                console.log(err);
            })
    };

    init();
};
},{}],"/root/systemanalysebogen/client/src/js/controller/sendHabelCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans
 */
'use strict';

module.exports = function ($routeParams, $location) {

    this.link = "";
    var that = this;


    function init() {
        that.link = 'https://' + $location.host() + '/habel?source=' + $routeParams.link;
    }

    init();
};
},{}],"/root/systemanalysebogen/client/src/js/controller/sendMfpCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans
 */
'use strict';

module.exports = function ($routeParams, $location) {

    this.link = "";
    var that = this;


    function init() {
        that.link = 'https://' + $location.host() + '/mfp?source=' + $routeParams.link;
    }

    init();
};
},{}],"/root/systemanalysebogen/client/src/js/controller/sendNsiCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans
 */
'use strict';

module.exports = function ($routeParams, $location) {

    this.link = "";
    var that = this;


    function init() {
        that.link = 'https://' + $location.host() + '/nsi?source=' + $routeParams.link;
    }

    init();
};
},{}],"/root/systemanalysebogen/client/src/js/controller/sendStartfaceCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans
 */
module.exports = function ($routeParams, $location) {

    this.link = "";
    var that = this;


    function init() {
        that.link = 'https://' + $location.host() + '/starface?source=' + $routeParams.link;
    }

    init();
};
},{}],"/root/systemanalysebogen/client/src/js/controller/starfaceCtrl.js":[function(require,module,exports){
/**
 * Created by jroerthmans
 */
module.exports = function ($scope, $filter, $location, $http, pageService) {
    var that = this;

    this.bearbeiter = "";
    this.headset = 0;
    this.connection = 0;
    this.internetConnection = 0;
    this.device = 0;

    this.sys = {
        kontakt: {},
        infrastruktur: {
            connection: [],
            internet: []
        },
        geraete: [],
        headsets: [],
        schnittstelle: {
            show_analog: false,
            analog: {},
            show_isdn: false,
            isdn: {},
            datenschutz: 'nein',
            faxReceive: 'nein',
            faxSend: 'nein',
            headset: 'nein'
        },
        revision: 0,
        bearbeiter: ""
    };

    this.connectionTypes = [
        {value: 1, text: 'ISDN Anlagenanschluss'},
        {value: 2, text: 'ISDN Mehrgeräteanschluss'},
        {value: 3, text: 'ISDN Primärmultiplexanschluss'},
        {value: 4, text: 'NGN Anschluss'},
        {value: 5, text: 'SIP Trunk'}
    ];

    this.internetTypes = [
        {value: 1, text: 'ADSL'},
        {value: 2, text: 'VDSL'},
        {value: 3, text: 'SDSL'},
        {value: 4, text: 'MPLS'},
        {value: 5, text: 'Sonstige'}
    ];

    this.devTypes = [
        {value: 1, text: 'Yealink T19P', image: './images/phones/Yealink_T19P.jpg'},
        {value: 2, text: 'Yealink T42G', image: './images/phones/Yealink_T42G.jpg'},
        {value: 3, text: 'Yealink T46G', image: './images/phones/Yealink_T46G.jpg'},
        {value: 4, text: 'Yealink T48G', image: './images/phones/Yealink_T48G.png'},
        {value: 5, text: 'Yealink T49G', image: './images/phones/Yealink_T49G.jpg'},
        {value: 6, text: 'Gigaset R630H PRO', image: './images/phones/R630H_PRO.jpg'},
        {value: 7, text: 'Gigaset R650H PRO', image: './images/phones/R650H_PRO.jpg'},
        {value: 8, text: 'Gigaset S650H PRO', image: './images/phones/S650H_PRO.png'},
        {value: 9, text: 'Gigaset SL750 PRO', image: './images/phones/SL750_PRO.jpg'}
    ];

    this.headsetTypes = [
        {value: 1, text: 'monaural'},
        {value: 2, text: 'binaural'}
    ];

    this.headsetConnection = [
        {value: 1, text: 'kabelgebunden'},
        {value: 2, text: 'bluetooth'}
    ];

    function init() {
        var searchObject;
        searchObject = $location.search();
        if (searchObject.hasOwnProperty('source')) {
            $http.get('/api/v1/starface/persist/' + searchObject.source)
                .then(function (res) {
                    that.sys = res.data;
                    that.loaded = true;
                    that.bearbeiter = that.sys.bearbeiter;
                    that.sys.bearbeiter = "";
                    that.headset = that.sys.headsets.length;
                    that.connection = that.sys.infrastruktur.connection.length;
                    that.internetConnection = that.sys.infrastruktur.internet.length;
                    that.device = that.sys.geraete.length;
                }, function (err) {
                    console.log(err);
                    $location.url($location.path());
                })
        } else {
            that.addInternetConnection();
            that.addDevice();
            that.addHeadset();
        }
    }

    this.getDatenschutzButtonState = function (state) {
        return that.sys.schnittstelle.datenschutz == state ? 'active' : '';
    };

    this.setDatenschutz = function (state) {
        that.sys.schnittstelle.datenschutz = state;
    };

    this.getDatenschutz = function () {
        return that.sys.schnittstelle.datenschutz;
    };

    this.getFaxSendButtonState = function (state) {
        return that.sys.schnittstelle.faxSend == state ? 'active' : '';
    };

    this.setFaxSend = function (state) {
        that.sys.schnittstelle.faxSend = state;
    };

    this.getFaxSend = function () {
        return that.sys.schnittstelle.faxSend;
    };

    this.getFaxReceiveButtonState = function (state) {
        return that.sys.schnittstelle.faxReceive == state ? 'active' : '';
    };

    this.setFaxReceive = function (state) {
        that.sys.schnittstelle.faxReceive = state;
    };

    this.getFaxReceive = function () {
        return that.sys.schnittstelle.faxReceive;
    };

    this.getHeadsetButtonState = function (state) {
        return that.sys.headset == state ? 'active' : '';
    };

    this.setHeadset = function (state) {
        that.sys.headset = state;
    };

    this.getHeadset = function () {
        return that.sys.headset;
    };

    this.addConnection = function () {
        that.connection++;
        this.inserted = {
            id: that.connection,
            carrier: "",
            kunden_nr: "",
            vertrags_nr: "",
            leitungs_nr: "",
            verbindungsTyp: ""
        };
        that.sys.infrastruktur.connection.push(this.inserted);
    };

    this.removeConnection = function (con) {
        that.sys.infrastruktur.connection.splice(that.sys.infrastruktur.connection.indexOf(con), 1);
    };

    this.removeInternetConnection = function (con) {
        that.sys.infrastruktur.internet.splice(that.sys.infrastruktur.internet.indexOf(con), 1);
    };

    this.addInternetConnection = function () {
        that.internetConnection++;
        this.inserted = {
            id: that.internetConnection,
            anzahl: 1,
            typ: "",
            download: 0,
            upload: 0
        };
        that.sys.infrastruktur.internet.push(that.inserted);
    };

    this.addDevice = function () {
        that.device++;
        this.inserted = {
            id: that.device,
            anzahl: 1,
            typ: ""
        };
        that.sys.geraete.push(that.inserted);
    };

    this.removeDevice = function (device) {
        that.sys.geraete.splice(that.sys.geraete.indexOf(device), 1);
    };

    this.startShowImage = function (devType) {
        that.showImage = true;
        that.imageToShow = JSON.parse(devType.typ).image;
        $(document).on('mousemove', function (e) {
            $('#deviceImage').css({
                left: e.pageX - 400,
                top: e.pageY - 400
            });
        });
    };

    this.stopShowImage = function () {
        $(document).off('mousemove');
        that.showImage = false;
    };

    this.addHeadset = function () {
        that.headset++;
        this.inserted = {
            id: that.headset,
            anzahl: 1,
            typ: "",
            connection: ""
        };
        that.sys.headsets.push(that.inserted);
    };

    this.removeHeadset = function (device) {
        that.sys.headsets.splice(that.sys.headsets.indexOf(device), 1);
    };

    this.export = function () {
        $('#sendModal').modal('hide');
        $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();

        $http.post('/api/v1/starface/persist', {
            name: that.sys.kontakt.kunde,
            value: JSON.stringify(that.sys),
            revision: that.sys.revision
        })
            .then(function (res) {
                pageService.go('/starface/send/' + res.data);
            }, function (err) {
                console.log(err);
            })
    };

    init();
};
},{}],"/root/systemanalysebogen/client/src/js/routes.js":[function(require,module,exports){
'use strict';

module.exports = function ($routeProvider, $locationProvider) {
    // route definition
    $routeProvider
        .when('/', {
            templateUrl: '../templates/auswahl.html'
        })
        .when('/mfp', {
            controller: 'mfpCtrl as mfp',
            templateUrl: '../templates/mfp/template.html',
            reloadOnSearch: false
        })
        .when('/mfp/send/:link', {
            controller: 'sendMfpCtrl as send',
            templateUrl: '../templates/mfp/send.html'
        })
        .when('/starface', {
            controller: 'starfaceCtrl as star',
            templateUrl: '../templates/starface/template.html',
            reloadOnSearch: false
        })
        .when('/starface/send/:link', {
            controller: 'sendStarfaceCtrl as send',
            templateUrl: '../templates/starface/send.html'
        })
        .when('/nsi', {
            controller: 'nsiCtrl as nsi',
            templateUrl: '../templates/nsi/template.html',
            reloadOnSearch: false
        })
        .when('/nsi/send/:link', {
            controller: 'sendNsiCtrl as send',
            templateUrl: '../templates/nsi/send.html'
        })
        .when('/habel', {
            controller: 'habelCtrl as habel',
            templateUrl: '../templates/habel/template.html',
            reloadOnSearch: false
        })
        .when('/habel/send/:link', {
            controller: 'sendHabelCtrl as send',
            templateUrl: '../templates/habel/send.html'
        })
        .otherwise({
            redirectTo: '/'
        });

    $locationProvider.html5Mode(true);
};
},{}],"/root/systemanalysebogen/client/src/js/service/index.js":[function(require,module,exports){
'use strict';

var app = angular.module('Systemanalysebogen');

app.service('pageService', ['$location', '$http', '$rootScope', '$compile', '$timeout', '$q', require('./pageChangeService')]);

},{"./pageChangeService":"/root/systemanalysebogen/client/src/js/service/pageChangeService.js"}],"/root/systemanalysebogen/client/src/js/service/pageChangeService.js":[function(require,module,exports){
'use strict';

module.exports = function($location, $http, $rootScope, $compile, $timeout, $q) {
    var that = this;

    this.go = function(path) {
        $location.path(path);
    };

    that.print = function(templateUrl, data) {
        $rootScope.isBeingPrinted = true;
        $http.get(templateUrl).then(function(templateData) {
            var template = templateData.data;
            var printScope = $rootScope.$new();
            angular.extend(printScope, data);
            var element = $compile($('<div>' + template + '</div>'))(printScope);
            var renderAndPrintPromise = $q.defer();
            var waitForRenderAndPrint = function() {
                if(printScope.$$phase || $http.pendingRequests.length) {
                    $timeout(waitForRenderAndPrint, 1000);
                } else {
                    that.printHtml(element.html()).then(function() {
                        $rootScope.isBeingPrinted = false;
                        renderAndPrintPromise.resolve();
                    });
                    printScope.$destroy();
                }
                return renderAndPrintPromise.promise;
            };
            waitForRenderAndPrint();
        });
    };

    this.printHtml = function(html) {
        var deferred = $q.defer();

        var hiddenFrame = $('<iframe style="display: none"></iframe>').appendTo('body')[0];
        $(hiddenFrame).load(function() {
            if(!hiddenFrame.contentDocument.execCommand('print', false, null)) {
                hiddenFrame.contentWindow.focus();
                hiddenFrame.contentWindow.print();
            }
            hiddenFrame.contentWindow.onafterprint = function() {
                $(hiddenFrame).remove();
            };
        });
        var htmlContent = '' +
            '<html>' +
            '<body>' +
            html +
            '</body>' +
            '</html>';

        var doc = hiddenFrame.contentWindow.document.open('text/html', 'replace');
        doc.write(htmlContent);
        doc.close();
        return deferred.promise;
    };

    this.openNewWindow = function(html) {
        var newWindow = window.open('printTest.html');
        newWindow.onload = function() {
            $(newWindow.document.body).html(html);
        };
    };
};
},{}]},{},["/root/systemanalysebogen/client/src/js/app.js"]);
